bw-bolt-field-error {
    margin-top: 0.5rem;
    display: inline-block;

    ul.error-list {
        padding: 0;
        margin: 0;
        margin-top: 0.2rem;

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
            margin-left: 4px;
            width: max-content;
        }
    }
}
