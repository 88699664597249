@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.bolt-table-responsive {
    td,
    th {
        line-height: 1.2;
    }
}

.bolt-table-blue th {
    background: Bolt.$palette-vibrantBlue;
}

.break-word {
    word-break: break-word;
}

btl-renewal-pending bolt-accordion {
    margin-bottom: 20px;
}

@media (max-width: 510px) {
    .bolt-table-responsive {
        overflow-y: clip;

        table {
            thead {
                display: none;
            }

            tbody,
            tr,
            td {
                display: block;
                width: 100%;
            }

            tbody tr:nth-child(odd) {
                background-color: Bolt.$palette-paleGray25;
            }

            td {
                border: none;
                padding-left: 50%;
                text-align: left;
                position: relative;

                &::before {
                    content: attr(data-label);
                    position: absolute;
                    left: 0;
                    width: 50%;
                    font-weight: 500;
                    text-align: left;
                    padding: 5px 10px;
                    font-size: 14px;
                    color: Bolt.$palette-vibrantBlue;
                    line-height: 1.2;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .bolt-table-responsive {
        table {
            td {
                padding: 25px 10px;

                &::before {
                    width: 100%;
                    top: 0;
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .bolt-table-responsive {
        th,
        td {
            font-size: 16px;
            padding: 10px 6px;
        }
    }
}

@media (max-width: 846px) {
    .bolt-table-responsive {
        th,
        td {
            font-size: 14px;
            padding: 10px 5px;
        }
    }
}
