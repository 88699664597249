@import "functions";

/* Please Wait and Spinner */

.wait-overlay {
    font-size: em(29px);

    // Removes browser "glow" on focused element
    &:focus {
        outline-color: transparent;
        outline-style: none;
    }
}
