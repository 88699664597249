@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.bolt-button-link.bolt-button {
    font-size: 1.125rem;
    padding: 0.75rem 1.25rem;
    line-height: 1.125rem;
}

.bolt-button-link--secondary.bolt-button {
    background: none;
    border: 1px solid #0067c5;
    color: #0067c5;
}

.bolt-button-link {
    box-sizing: border-box;
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: "nw-primary", "proxima-nova", "Helvetica Neue", "Helvetica", sans-serif;
    border-radius: 4px;
    color: #fff;
    background: #0067c5;
    border: 0;
    font-weight: 400;
    text-decoration: none;
    width: 100%;
    transition: all 350ms;
}
