.esri-view {
    height: 500px;
}

.modal-backdrop.fade {
    opacity: 0.5;
    z-index: 100 !important; /* stylelint-disable-line declaration-no-important */
}

.modal-header,
.modal-footer {
    display: block;
    justify-content: normal;
    align-items: normal;
}

.map-modal .modal-dialog,
.map-modal.fade .modal-dialog {
    transform: none;
    max-width: 800px;
    margin: 30px auto;
}

.map-modal .modal-body {
    height: 500px;
}

.map-height,
.media,
.media-body {
    height: 100%;
}

@media (max-width: 736px) {
    .map-modal .modal-dialog,
    .map-modal.fade .modal-dialog .modal-content {
        margin: 0;
        height: 99%;
        max-width: 100%;
        padding: 0;
    }

    .modal-header {
        height: 12%;
    }

    .modal-body {
        height: 68%;
    }

    .modal-footer {
        height: 20%;
    }

    .map-height {
        height: 100%;
    }

    .map-height .media {
        height: 100%;
    }

    .map-height .media-body {
        display: block;
        height: 100%;
        width: 100%;
    }

    .esri-view {
        height: 100%;
    }
}

//@media(max-width: 415px){
//  .esri-view{
//    height: 460px;
//  }
//}
//
//@media(max-width: 377px){
//  .esri-view{
//    height: 393px;
//  }
//}

//@media(max-width: 362px){
//  .esri-view{
//    height: 270px;
//  }
//}
//
//@media(max-width: 325px){
//  .esri-view{
//    height: 300px;
//  }
//}
