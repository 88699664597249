@import "_mixins";

/*
| Content Modules
| Generic containers for content that may not be specific to any one view
*/

// Generic skeleton for horizontal floating list
// Default assumes 4up with equal widths
ul.stack-list {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;

    li {
        margin: 0;
        padding: 0;
        float: left;
        width: 25%;
    }
}

//
// Flag Object Model (Media object)
// http://csswizardry.com/2013/05/the-flag-object/
// Use it for anything that needs media inline with content; icons, images, etc.
//
//	<div class="mediabox">
//	    <div class="mediabox__image">
//	        <img src="photo.jpg">
//	    </div>
//	    <div class="mediabox__body">
//	        Copy aligns to the right of the media
//	    </div>
//	</div>
//
//	Modifier classes
//
//	.mediabox.mediabox--top			Aligns content to the top
//	.mediabox.mediabox--bottom		Aligns content to the bottom
//	.mediabox.mediabox--right		Use when media needs to be on the right
//
//
.mediabox {
    display: table;
    width: 100%;
}

.mediabox__body,
.mediabox__image {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.mediabox__body {
    width: 100%;
}

.mediabox__image {
    padding-right: 10px;

    > img {
        display: block;
        max-width: none;
    }

    .mediabox--right & {
        padding-right: 0;
        padding-left: 10px;
    }
}

.mediabox.mediabox--top {
    .mediabox__image,
    .mediabox__body {
        vertical-align: top;
    }
}

.mediabox.mediabox--bottom {
    .mediabox__image,
    .mediabox__body {
        vertical-align: bottom;
    }
}
