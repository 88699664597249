//| Variables
$color-amount: #e65100; // premium orange
$color-cma: #598400; // Green
$color-active: #5e9cae; // Teal
$color-label: #0071bf; // Blue

//| Icon font variables
$icon-auto-neg: "\e651";
$icon-auto: "\e652";

$icon-banking-neg: "\e653";
$icon-banking: "\e654";

$icon-billing-neg: "\e655";
$icon-billing: "\e656";

$icon-checking-neg: "\e657";
$icon-checking: "\e658";

$icon-checkmark-neg: "\e659";
$icon-checkmark: "\e65a";

$icon-claims-neg: "\e65b";
$icon-claims: "\e65c";

$icon-creditcard-neg: "\e65d";
$icon-creditcard: "\e65e";

$icon-document-neg: "\e65f";
$icon-document: "\e660";

$icon-fdic-neg: "\e661";
$icon-fdic: "\e662";

$icon-protection-neg: "\e663";
$icon-protection: "\e664";

$icon-home-neg: "\e665";
$icon-home: "\e666";

$icon-idcard-neg: "\e667";
$icon-idcard: "\e668";

$icon-info-neg: "\e669";

$icon-minus-neg: "\e66a";
$icon-minus: "\e66b";

$icon-onyourside-neg: "\e66c";
$icon-onyourside: "\e66d";

$icon-person-neg: "\e66e";
$icon-person: "\e66f";

$icon-plus-neg: "\e670";
$icon-plus: "\e671";

$icon-retirement-neg: "\e672";
$icon-retirement: "\e673";

$icon-savings-neg: "\e674";
$icon-savings: "\e675";

$icon-security-neg: "\e676";
$icon-security: "\e677";

$icon-warning-circle-neg: "\e678";
$icon-warning: "\e679";

$icon-warning-square-neg: "\e67a";
$icon-warning-square: "\e67b";

$icon-warning-triangle-neg: "\e67c";
$icon-warning-triangle: "\e67d";

$icon-xclose-neg: "\e67e";
$icon-xclose: "\e67f";

$icon-connection: "\e600";
$icon-print: "\e601";

$icon-arrow-up: "\e616";
$icon-arrow-down: "\e61a";

$icon-arrow-left2: "\e64a";
$icon-arrow-down2: "\e64b";
$icon-arrow-up2: "\e64c";

//| MIXINS
//|

@mixin border-radius($radius) {
    border-radius: $radius;
}

@mixin gradient($from-color, $to-color) {
    background-color: mix($from-color, $to-color); /* Fallback */
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient(top, $from-color, $to-color);
    background-image: -moz-linear-gradient(top, $from-color, $to-color);
    background-image: -ms-linear-gradient(top, $from-color, $to-color);
    background-image: -o-linear-gradient(top, $from-color, $to-color);

    /* -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='$from-color', EndColorStr='$to-color')"; */
}

@mixin transition($property, $duration, $type) {
    -webkit-transition: $property $duration $type;
    -moz-transition: $property $duration $type;
    -ms-transition: $property $duration $type;
    -o-transition: $property $duration $type;
    transition: $property $duration $type;
}

@mixin opacity($opacityWhole, $opacityDecimal) {
    zoom: 1;

    /* Theoretically for IE 8 & 9 (more valid) */

    /* ...but not required as filter works too */

    /* should come BEFORE filter */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacityWhole)";

    /* This works in IE 8 & 9 too */

    /* ... but also 5, 6, 7 */
    filter: alpha(opacity=$opacityWhole);

    /* Older than Firefox 0.9 */
    -moz-opacity: $opacityDecimal;

    /* Safari 1.x (pre WebKit!) */
    -khtml-opacity: $opacityDecimal;

    /* Modern!
  /* Firefox 0.9+, Safari 2?, Chrome any?
  /* Opera 9+, IE 9+ */
    opacity: $opacityDecimal;
}

@mixin box-shadow($horiz, $vert, $blur, $spread, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $horiz $vert $blur $spread $color;
        -moz-box-shadow: inset $horiz $vert $blur $spread $color;
        box-shadow: inset $horiz $vert $blur $spread $color;
    } @else {
        -webkit-box-shadow: $horiz $vert $blur $spread $color;
        -moz-box-shadow: $horiz $vert $blur $spread $color;
        box-shadow: $horiz $vert $blur $spread $color;
    }
}

@mixin disable-select() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin clear {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

// Media query shortcut, converts pixels to em sizing; change $base if you're not using 16px as base
@mixin screensize($pixels, $base: 16) {
    @media print, screen and (max-width: calc($pixels/$base)+em) {
        @content;
    }
}

@mixin breakpoint($point) {
    @if $point == desktop {
        @media (min-width: 1080px) {
            @content;
        }
    } @else if $point == tab-land {
        @media (min-width: 1023px) {
            @content;
        }
    } @else if $point == tablet {
        @media (min-width: 767px) {
            @content;
        }
    } @else if $point == mob-large-land {
        @media (min-width: 735px) {
            @content;
        }
    } @else if $point == mob-medium-land {
        @media (min-width: 663px) {
            @content;
        }
    } @else if $point == mobile-large {
        @media (min-width: 413px) {
            @content;
        }
    } @else if $point == mobile-medium {
        @media (min-width: 374px) {
            @content;
        }
    } @else if $point == mobile-land {
        @media (min-width: 567px) {
            @content;
        }
    } @else if $point == mobile {
        @media (min-width: 320px) {
            @content;
        }
    }
}
