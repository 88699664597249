app-billing-tabs .billing-tab {
    text-align: center;
}

@media (max-width: 766px) {
    is-bread-crumbs,
    app-billing-tabs {
        display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    authenticated-header header {
        border-bottom: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
    }

    app-billing-tile .tile {
        margin-bottom: 5px;
    }

    #timeline-navigation #past-tab > span,
    #timeline-navigation #current-tab > span,
    #timeline-navigation #future-tab > span {
        padding-top: 6px;
    }
}

@media (max-width: 449px) {
    authenticated-header header {
        margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    app-billing-tile .tile {
        margin-top: 12px;
        margin-bottom: 2px;
    }

    #timeline-navigation #past-tab > span,
    #timeline-navigation #current-tab > span,
    #timeline-navigation #future-tab > span {
        padding-top: 8px;
    }
}
