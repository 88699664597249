/* Bolt Accordions Overrides */

.nw-accordion .panel-title a {
    padding: 14px 36px 10px 14px;
}

.nw-accordion .coverage-panel .panel-title a {
    padding: 0;
}

.nw-accordion .panel-open .panel-title a::before,
.nw-accordion .panel-title a::before {
    position: absolute;
    left: 0;
    content: "";
    display: none;
    top: 0;
    margin-top: 0;
    border-top: none;
    border-bottom-width: 0;
    border-bottom-style: none;
    border-left: none;
    border-right: none;
}

.nw-accordion .panel-open .panel-title a::after {
    top: 50%;
    margin-top: -10px;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
}

.nw-accordion .panel-title a::after {
    position: absolute;
    right: 15px;
    content: "";
    display: inline-block;
    top: 50%;
    margin-top: -3.5px;
    border-top: 7px solid;
    border-bottom-width: 0;
    border-bottom-style: none;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
}

@media screen and (max-width: em(412px)) {
    .nw-accordion .panel-title a {
        padding: 14px 25px 10px 14px;
    }

    .nw-accordion .coverage-panel .panel-title a {
        padding: 0;
    }

    .nw-accordion .panel-title a::after {
        right: 7px;
    }
}

.panel-body {
    padding: 0;
}

/* Custom ACCORDIONS */
.accordion-container {
    margin: 0 20px 20px;

    .panel {
        margin-bottom: 15px;
    }
}

.accordion-container--hidden {
    display: none;
}

.accordion-container--inner {
    margin: 0 0 20px;
}

.accordion__houseHoldMember__content {
    padding: 15px 10px 0;
}

@media screen and (max-width: em(450px)) {
    .accordion__driver__content,
    .accordion__houseHoldMember__content {
        padding: 30px 10px 10px;
    }
}
