app-personal-billing-details {
    #page-content {
        bolt-accordion-group {
            btl-renewal {
                bolt-accordion {
                    btl-timeline-header {
                        bolt-notification {
                            #timeline-notification-header {
                                p {
                                    display: inline;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            btl-timeline-bill {
                btl-timeline-header {
                    bolt-notification {
                        #timeline-notification-header {
                            p {
                                display: inline;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                btl-amount-due {
                    #due-amount {
                        is-interpolate p {
                            display: inline;
                            margin-bottom: 0;
                        }
                    }
                }

                btl-events {
                    #events {
                        is-interpolate {
                            p {
                                display: inline;
                                margin-bottom: 0;
                            }
                        }

                        .currentBillDisclaimer {
                            is-interpolate {
                                p {
                                    display: inline;
                                    margin-bottom: 0;
                                    font-size: small;
                                }
                            }
                        }

                        #billing-account {
                            .event-paragraph {
                                margin-bottom: 0;

                                p {
                                    display: inline;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                btl-fees {
                    #fees {
                        bolt-contextual-help {
                            #bolt-context-help-ul {
                                is-interpolate p {
                                    display: inline;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    btl-policy-info {
        #policy-info {
            div.bolt-row {
                display: flex;
                justify-content: space-between;

                > div.bolt-col-6 {
                    max-width: 48%;
                }
            }
        }
    }
}
