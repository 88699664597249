/* HELPERS */
.group::before,
.group::after {
    content: "";
    display: table;
}

.group::after {
    clear: both;
}

.group {
    zoom: 1;
}

.hidden,
.semantic {
    position: absolute;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.noPadding {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.clean,
.clean li,
.clean dt,
.clean dd {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

a.underline {
    text-decoration: underline;
}

.upper-case {
    text-transform: uppercase;
}

.clear {
    clear: both;
    height: 1px;
    overflow: hidden;
}

.nowrap {
    white-space: nowrap;
}

.strong {
    font-weight: bold;
}

.button-margin {
    margin-right: 10px;
}

.divider {
    width: 100%;
    border-top: 2px dotted #bbbbbc;
}

.bordered {
    width: 100%;
    border-top: 2px dotted #bbbbbc;
    padding: 0 0 30px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.overlay-child {
    display: none;
}

.js .show-overlay .overlay {
    @include opacity(70, 0.7);

    z-index: 998;
}

// Turns on overlay
.js .show-overlay .overlay-focus {
    z-index: 999;
}

.js .show-overlay .overlay-child {
    z-index: 999;
    display: block;
}

// Overrides for Bolt Bootstrap
.nw-arrow::before {
    margin: 0 0 3px;
}

// Toggler up/down arrow
.toggler {
    position: relative;
}
