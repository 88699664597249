/* PRINT STYLES */
@media print {
    a[href]::after {
        content: "" !important; /* stylelint-disable-line declaration-no-important */
    }

    .breadcrumb {
        display: none;
    }

    .progress {
        display: none;
    }

    .nw-progress {
        display: none;
    }

    .nw-breadcrumbs {
        display: none;
    }

    .main-footer__contact {
        width: 100%;
    }

    .button-container {
        display: none;
    }

    .accordion__arrow {
        display: none;
    }

    .accordion__panel {
        display: block;
    }

    .overlay {
        display: none;
    }

    .js .accordion__panel--variable {
        display: block;
    }

    .btn {
        display: none;
    }

    .alert-danger {
        -webkit-print-color-adjust: exact;
        display: block;

        /* background-color: #f2dede !important; */
        background-color: unset !important; /* stylelint-disable-line declaration-no-important */
        box-shadow: inset 0 0 0 1000px #f2dede !important; /* stylelint-disable-line declaration-no-important */
        border-color: #ebccd1 !important; /* stylelint-disable-line declaration-no-important */
        color: #a94442 !important; /* stylelint-disable-line declaration-no-important */
    }

    .no-print {
        display: none;
    }

    .section-header > h2 {
        color: #fafafa;
        font-weight: 400;
    }

    #footer_disclosureContent a {
        color: #005691 !important; /* stylelint-disable-line declaration-no-important */
    }
}
