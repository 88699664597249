.nav-options-container {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
}

.nav-options-container__header-container {
    width: 100%;
    display: block;
    padding: 20px 20px 0;
}

.nav-options-container__header-container--promo {
    padding-bottom: 20px;
}

.nav-options__buttons {
    padding: 10px 20px 20px;
}

.header-container__secondary-info p,
.policy-messages p {
    margin-bottom: 0;
}

.header-icon::before {
    content: "";
    display: block;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
}

.nav-options-container__content {
    width: 100%;
    &.test-drive-scenario {
        min-height: 316px;
    }
}

.header-container__description {
    padding-left: 40px;
    float: left;
}

.nav-options-container__promo {
    width: 100%;
    background-color: #f2f2f2;
    border-top: 1px solid #c4c4c4;
    position: relative;
}

.nav-options-container__promo-details {
    padding: 20px 20px 20px 10%;
}

// Inner toggle trigger and arrow
.promo-details__toggle {
    position: absolute;
    right: 20px;
    top: 20px;
    padding-right: 16px;
}

@include screensize(600) {
    .promo-details__toggle {
        position: relative;
        display: block;
        right: 0;
        top: 0;
        text-align: right;
        margin: -10px 20px 10px 0;
    }
}

.nav-options-container__promo-details a {
    position: relative;
    display: inline-block;
}

.nav-options-container__promo-details a::after {
    display: inline-block;
    content: "";
    margin-left: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #e65100;
    margin-top: 4px;
}

.nav-options-container__promo-details.noarrow a::after {
    border: none;
}

.header-container__secondary-info {
    width: 50%;
    float: right;
    text-align: right;
    margin-bottom: 15px;
}

span.line {
    display: block;
}

span.line--primary {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 1.2em;
}

span.line--secondary {
    font-size: 0.85em;
    line-height: 1.3em;
}

span.line--icon.line--warning {
    color: #f00;

    &::before {
        font-family: "nw-icons";
        content: $icon-warning-square-neg;
        color: #f00;
        margin-right: 3px;
        font-size: 1.3em;
    }
}

.amount {
    font-size: 1.5em;
    font-weight: normal;
}

.amount--orange {
    color: #e65100;
}

.fixed-stub-warning {
    position: fixed;
    top: 0;
    color: #fff;
    background-color: #f00;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;
    z-index: 1500;
    font-size: 16px;
}

// Error message
$color_error: #c00;
$bgcolor_error: #fcdada;

.nav-options__error {
    position: relative;
    border-top: solid 1px $color_error;
    padding: 15px 15px 15px 60px;
    color: $color_error;
    background-color: $bgcolor_error;
    margin-top: 10px;
    min-height: 65px;

    .icon {
        position: absolute;
        height: 33px;
        width: 33px;
        background-position: -72px -468px;
        left: 15px;
    }

    // Carot arrow with border
    &::after,
    &::before {
        bottom: 100%;
        left: 90px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::after {
        border-color: #88b7d500;
        border-bottom-color: $bgcolor_error; // fill color
        border-width: 15px;
        margin-left: -15px;
    }

    &::before {
        border-color: #c2e1f500;
        border-bottom-color: $color_error; // stroke color
        border-width: 16px;
        margin-left: -16px;
    }
}

@media screen and (max-width: 48.75em) {
    /* 780px */
    .nav-options__buttons .btn {
        margin-bottom: 10px;
        margin-right: 0;
        width: 51%;
    }
}

@media screen and (max-width: 38.75em) {
    /* 620px */

    .header-container__secondary-info {
        width: 100%;
        float: none;
        display: block;
        text-align: left;
        margin-bottom: 15px;
    }

    .header-container__secondary-info .button-right {
        float: left;
        width: 65%;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .header-container__secondary-info span.break {
        display: block;
    }

    .nav-options__buttons .btn {
        width: 65%;
        margin-bottom: 10px;
        margin-right: 0;
    }

    span.line--icon.line--warning::before {
        content: "";
    }

    span.line--icon.line--warning::after {
        font-family: "nw-icons";
        content: $icon-warning-square-neg;
        color: #f00;
        margin-left: 3px;
        font-size: 1.3em;
    }
}

@media screen and (max-width: 31.25em) {
    /* 500px */
    .policy-content__left {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding-right: 20px;
    }

    .policy-content-right__col {
        width: 100%;
        float: none;
        display: block;
    }
}

@media screen and (max-width: 28.125em) {
    /* 450px */
    .nav-options-container__header-container {
        padding: 10px 10px 0;
    }

    .nav-options__buttons {
        padding: 10px 10px 20px;
    }

    .policy-content__left {
        padding-left: 10px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 25.625em) {
    /* 410px */
    .nav-options__buttons .equal-width {
        width: 100%;
    }

    .header-container__secondary-info .equal-width {
        width: 100%;
    }
}
