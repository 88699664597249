/* TODO: Created this fix file to ensure it is not breaking the exisiting CSS designs */

/* Once all page has been migrated to the bolt this file will be deleted */

/* after all pages styles applied will remove the below fix */

/* Below line is to override text-transform which is applied in bolt-bootstrap.css */
bolt-accordion-group {
    div {
        h2 {
            text-transform: none;
        }
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }
}

h4.bolt-heading {
    font-weight: 700;
    font-size: 18px;
    font-family: "nw-primary", Gotham, "HCo Gotham", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.bolt-heading {
    font-size: 16px;
    font-weight: 600;
    font-family: "nw-primary", Gotham, "HCo Gotham", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.nw-social-icon {
    width: 38px;
    padding: 2px;
    border-radius: 33%;
    display: inline-block;
}

app-billing-base {
    h1 {
        font-size: 2em;
        margin: 0.67em 0;
    }

    nav {
        clear: both;
    }
}

.bolt-footer-alignment {
    padding: 52px 84px !important; /* stylelint-disable-line declaration-no-important */
}

.bolt-typography .bolt-footer a {
    text-decoration: none;
}

.bolt-typography .bolt-footer a:hover {
    text-decoration: underline;
}

.bolt-typography {
    body {
        font-size: medium;
        font-family: sans-serif;
    }

    .global-container {
        font-size: medium;
    }

    li {
        text-indent: 0;
    }

    footer a,
    header a {
        border-bottom: 0;
    }

    btl-timeline-navigation {
        a,
        a:hover,
        a:focus {
            border-bottom: 0;
            text-decoration: none;
        }
    }

    is-bread-crumbs {
        strong,
        b {
            font-size: 1.1rem;
            font-weight: normal;
        }

        nav {
            clear: both;
        }

        li {
            padding-left: 0;
        }
    }

    header {
        ul.utility-panel-links {
            a {
                text-indent: 0;
            }
        }

        line-height: 1.25rem;
    }
}

/** TODO: once modal styles migrated to bolt will remove this **/
is-app-modal-wrapper {
    font-size: 1.1rem;
}

.border-hr {
    box-shadow: 0 -1px 0 #d8d8d8;
}

.color-white {
    color: white !important; /* stylelint-disable-line declaration-no-important */
}

.bolt-space-left-xs {
    margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.aligment-left {
    text-align: left;
}

.aligment-right {
    text-align: right;
}

.margin-top-lg {
    margin-top: 1.6rem;
}

.margin-top-xl {
    margin-top: 2rem;
}

.margin-top-md {
    margin-top: 12px;
}

/* will be taken off once footer implemented.
All TODO and related unwanted styles for phase 1 has removed.
Still needed this after completion of phase 2 should revisit and take */

/* stylelint-disable selector-main-footer, ul, li, no-descending-specificity */
.main-footer ul li {
    text-indent: 0;

    a {
        border-bottom: 0;
    }
}
