/*
| Policy Documents
|
*/

/*
| START Policy Documents
| tf modifications on 12/16/2014
*/

.doc-title {
    position: absolute;
    top: 10px;
    width: 63%;
}

.doc-title-customization {
    position: static;
    top: 10px;
    width: 63%;
    float: left;
}

.customization-msg-margin {
    margin-top: 7px;
}

.multipledocsgrouping-padding {
    padding-bottom: 5px;
    padding-top: 20px;
}

.multipledocsgrouping-leftpadding {
    padding-left: 40px;
}

.mediabox__body .button-right {
    width: 205px;
}

@media print, screen and (max-width: 45em) {
    .doc-title {
        width: 100%;
        position: static;
        top: 0;
    }

    .mediabox__body .button-right {
        position: relative;
        float: left;
        display: block;
        margin-top: 10px;
    }
}

/*
| END Policy Documents
| tf modifications on 12/16/2014
*/
