/* GLOBAL HEADING STYLES */

h1 {
    font-family: "proxima-nova", Arial, sans-serif;
    font-size: 2.2em;
    color: #666;
    text-transform: capitalize;
    font-weight: 300;
    margin: 0 10px 20px;
}

h2 {
    margin: 0;
}

h3 {
    font-weight: normal;
    margin: 0 0 20px;
}

h4 {
    font-weight: normal;
    margin: 0 0 10px;
}

h5 {
    margin: 0 0 10px;
}

/* UNIQUE HEADER STYLES */
.section-header {
    background: #666;
    padding: 15px 20px;
    margin-bottom: 20px;
    position: relative;
}

.required span:first-child::after {
    content: "\0000a0*";
}

.text-field-label.required {
    white-space: nowrap;
}

.section-header.mb0px {
    margin-bottom: 0;
}

.section-header--form {
    background: #e7e7e7;
}

.section-header h2 {
    float: left;
    position: relative;
    color: #fff;
    margin: 0 5px 0 0;
}

.section-header--form h2 {
    color: #333;
    text-transform: none;
}

.section-header--button h2 {
    padding-top: 4px;
}

.section-header__action {
    float: right;
    margin-left: auto;
    margin-right: 20px;
    padding-top: 5px;
    margin-bottom: 0;
}

.section-header__nonaction {
    float: right;
    color: #fff;
    vertical-align: middle;
    margin-left: auto;
    padding-top: 8px;
}

.section-header--form__nonaction {
    color: #333;
}

.section-header--help {
    font-weight: normal;
    font-size: 0.65em;
}

.section-footer {
    background: #cce1ef;
    padding: 15px 20px;
    margin-bottom: 20px;
    position: relative;
    height: 100px;
}

.confirmation-documents {
    margin-bottom: 10px;

    p {
        margin: 0;
    }
}

/* GLOBAL TEXT STYLES */
p {
    margin: 0;
    line-height: 1.4em;
}

a {
    text-decoration: none;
    color: #005691;
}

a:hover,
a:focus,
a:active {
    text-decoration: underline;
}

sup {
    height: 0;
    line-height: 1;
    vertical-align: baseline;
    _vertical-align: bottom;
    position: relative;
    bottom: 1ex;
    font-size: 0.65em;
}

hr {
    height: 1px;
    width: 100%;
    margin: 0 0 20px;
    border: none;
    background: none;
}

abbr {
    border: none;
}

/* UNIQUE TEXT STYLES */
.text-small {
    font-size: 0.85em;
    text-transform: none;
    font-weight: normal;
}

.urbo {
    padding: 20px 0 10px 10px;
    font-size: 0.8em;
    color: #757575;
    background: #fff;
}

.disabled-phone-link {
    pointer-events: none;
}

.disabled-phone-link > a {
    text-decoration: none;
    color: inherit;
}

.link--additional-info {
    display: inline-block;
    border-bottom: 1px dotted #005691;
}

.link--additional-info:hover,
.link--addition-info:focus,
.link--addition-info:active {
    text-decoration: none;
}

.link--remove {
    display: inline-block;
    font-weight: normal;
}

.link--remove::before {
    display: block;
    float: left;
    content: "x";
    width: 10px;
    height: 10px;
    padding: 0 0 7px 4px;
    margin: 1px 5px 0 0;
    background: #005590;
    color: #fff;
    font-size: 0.8em;
}

.content-with-remove {
    display: inline-block;
    margin-right: 25px;
}

.font-proxima {
    font-family: "proxima-nova", Arial, sans-serif;
}

.inline-image {
    vertical-align: middle;
    margin: 0 5px;
}

.text-instructions {
    float: right;
}

@media screen and (max-width: 37.5em) {
    /* 600px */
    h1 {
        font-size: 1.7em;
    }

    .section-header {
        padding: 15px 10px 10px;
    }

    .section-header__action {
        margin-right: 10px;
    }

    .section-header__action h2 {
        max-width: 55%;
    }

    .section-header--long-content h2 {
        width: 100%;
        margin-bottom: 5px;
    }

    .section-header--long-content .section-header__action {
        display: block;
        float: none;
    }

    .section-header--long-content .section-header__nonaction {
        display: block;
        float: none;
        margin-left: 0;
    }

    .section-header--help {
        display: block;
    }
}

@media screen and (max-width: 28.125em) {
    /* 450px */
    h1 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 1.8em;
        margin-bottom: 10px;
    }

    .section-header__nonaction {
        padding-top: 2px;
    }

    .section-header__action h2 {
        padding-top: 5px;
        max-width: 45%;
    }

    .section-header--long-content h2 {
        width: 100%;
        margin-bottom: 5px;
        max-width: none;
    }

    .section-header--help {
        margin-bottom: 10px;
    }

    .section-header--button .section-header__nonaction {
        float: none;
        width: 100%;
    }

    .section-header--button .section-header__nonaction .btn {
        display: block;
    }

    .link--remove::before {
        padding: 0 0 7px 4px;
    }
}

.nw-inline-help__content-container.open {
    max-height: 200px;
}

.nw-inline-help__content {
    padding: 10px 20px;
    max-width: none;
    max-height: 200px;

    & p {
        margin: 0;
    }

    @media screen and (max-width: 28.125em) {
        font-size: 1.1em;
    }

    @media screen and (min-width: 568px) {
        font-size: 15px;
    }
}

.mind-form {
    margin-bottom: 21px;
}
