//+-
//| ICONS AND SPRITES
//| All standalone icons regardless of section/page
//+-

//| SPRITES
//|

.icon-sprite {
    background-image: url("../../images/icon_sprite.png");
    background-repeat: no-repeat;
}

.before-sprite::before {
    background-image: url("../../images/icon_sprite.png");
    background-repeat: no-repeat;
}

//| FONT ICONS
//| Icon variable definitions are in _mixins.scss

// Define icon font
@font-face {
    font-family: "nw-icons";
    src: url("../../css/fonts/nw-icons.eot");
    src:
        url("../../css/fonts/nw-icons.eot?#iefix") format("embedded-opentype"),
        url("../../css/fonts/nw-icons.woff") format("woff"),
        url("../../css/fonts/nw-icons.ttf") format("truetype"),
        url("../../css/fonts/nw-icons.svg#nw-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}
