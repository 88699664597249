/* Bolt Overrides */

.alert {
    display: inline-block;
}

.alert p {
    display: inline-block;

    &:last-of-type {
        margin: 0;
    }
}

.media-body {
    vertical-align: middle;

    & p {
        margin-bottom: 10px;
    }
}

.media {
    .media-left {
        width: 10%;
    }
}

.media-left {
    vertical-align: middle;
}

.field-level .alert {
    padding-right: 20px;
    width: initial;
}

.alert [class^="nw-icon-"] {
    font-size: 24px;
}

.field-level .alert .media-body {
    padding-right: 20px;
    width: initial;
}

.media {
    align-items: center;
}

.modal-open .modal {
    overflow: hidden;
}

.modal-title {
    & p {
        margin: 0;
        display: inline-block;
    }
}

.modal.fade .modal-dialog {
    transform: none;
}

.field-spacing .alert {
    margin-bottom: 0;
}

@media (max-width: 736px) {
    .modal-body .media-body {
        overflow: scroll;
        max-height: 250px;
    }
}
