@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolts;

@font-face {
    font-family: "Tiempos Headline";
    src:
        url("https://media.nationwide.com/bolt/resources/fonts/Tiempos-Headline-600.woff") format("woff"),
        url("https://media.nationwide.com/bolt/resources/fonts/Tiempos-Headline-600.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

.bolt-heading-font {
    font-family: "Tiempos Headline", "nw-primary", "proxima-nova", "Helvetica Neue", "Helvetica", sans-serif;
}

.bolt-heading-primary {
    font-family: "Tiempos Headline", "nw-primary", "proxima-nova", "Helvetica Neue", "Helvetica", sans-serif;
    text-transform: none;
    font-size: 2.8rem;
    color: Bolts.$palette-vibrantBlue;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

@media (max-width: 756px) {
    .manage-payment-form-container {
        padding: 0;
    }

    .bolt-heading-primary {
        font-size: 2rem;
    }
}

@media (max-width: 896px) {
    .manage-payment-form-container {
        padding: 0;
    }

    .bolt-heading-primary {
        font-size: 2rem;
    }
}

@media (max-width: 346px) {
    .bolt-heading-primary {
        font-size: 1.6rem;
    }
}
