div,
nav,
footer,
section,
form,
fieldset,
legend,
input,
select,
ul,
li,
a,
h1,
h2,
h3,
h4,
span {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

li {
    margin-bottom: 0;
}

body {
    height: 100%;
    font-size: 10px;
    background-color: #fff;
    color: #333;
    line-height: inherit;
}

body.modal-open {
    overflow: auto;
}

body.modal-open[style] {
    padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

/* REGIONS */

.content {
    width: 80%;
}

.overlay {
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #333;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
}

a[ng-click] {
    /* Styles for anchors without href but WITH ng-click */
    cursor: pointer;
}

/* LAYOUT STYLES */

.global-container {
    font-size: 1.7em;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    background: #fff;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.outer-container {
    width: 100%;
    height: 100%;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
}

.inner-container {
    height: 100%;
    margin-top: 10px;
}

.btn-back-padding {
    padding-bottom: 20px;
}

.main-content {
    width: 100%;
    padding: 20px;
    margin: 0;
    background: #fff;
    -webkit-text-size-adjust: none;
}

.page-content {
    width: 100%;
    padding: 0 20px 20px;
    margin: 0;
    background: #fff;
    -webkit-text-size-adjust: none;
}

.form-content {
    width: 100%;
    padding: 0 120px 20px;
    margin: 0;
    background: #fff;
    -webkit-text-size-adjust: none;
}

.indented-content {
    width: 100%;
    padding: 20px 120px;
    margin: 0;
    background: #fff;
    -webkit-text-size-adjust: none;
}

.landing-content {
    background-color: #e5f0f7;
    padding: 20px;
}

@media screen and (max-width: 62.1875em) {
    /* 995px */
    .form-content {
        padding: 20px 60px;
    }

    .indented-content {
        padding: 20px 60px;
    }
}

@media screen and (max-width: 52.5em) {
    /* 840px */
    .form-content {
        padding: 20px;
    }

    .indented-content {
        padding: 20px 60px;
    }
}

@media screen and (max-width: 48.75em) {
    /* 780px */
    .content {
        width: 100%;
    }
}

@media screen and (max-width: 37.5em) {
    /* 600px */
    .main-content {
        padding: 10px;
    }

    .page-content {
        padding: 10px;
    }

    .form-content {
        padding: 10px;
    }

    .indented-content {
        padding: 10px;
    }

    .landing-content {
        padding: 10px;
    }
}

.pt0px.form-content {
    padding-top: 0;
}

@media screen and (max-width: 28.125em) {
    /* 450px */
    .global-container {
        font-size: 1.7em;
    }

    .main-footer {
        padding: 10px;
    }
}

@media screen and (max-width: 28.125em) {
    /* 450px */
    .help-block {
        font-size: 12px;
    }
}
