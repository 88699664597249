@import "partials/policy-variables";
@import "functions";

/* BREADCRUMB */
.nw-breadcrumbs {
    border-top: 1px solid #ccc;
    padding: 10px;
    margin: 0;

    & a:first-of-type, // Fist link (home)
  & i:nth-of-type(2), // second chevron
  & a:nth-last-of-type(1), // last anchor before current
  & i:last-of-type {
        display: inline-block;
    }

    & .collapse-crumb,
    & i:first-of-type.collapse-crumb {
        display: none;
    }
}

.breadcrumb--top {
    border-bottom: 1px solid #ccc;
}

.breadcrumb--bottom {
    border-top: 1px solid #ccc;
}

@media screen and (max-width: em(600px)) {
    .nw-breadcrumbs {
        & a:nth-of-type(2), // second link (Auto Policy),
    & a,
    & i {
            display: none;
        }

        & a:first-of-type, // Fist link (home)
    & a:nth-of-type(2), // second link (Auto Policy)
    & i:nth-of-type(2), // second chevron
    & a:nth-last-of-type(1), // last anchor before current
    & i:last-of-type {
            display: inline-block;
        }

        & .collapse-crumb,
        & i:first-of-type.collapse-crumb {
            display: inline-block;
        }
    }

    .breadcrumb {
        padding: 10px 0;
    }

    .nw-breadcrumbs a:last-of-type,
    .nw-breadcrumbs a:only-child {
        display: inline-block;
        position: relative;
        z-index: 1;
    }
}

@media screen and (max-width: em(450px)) {
    .breadcrumb--top {
        display: none;
    }
}

@media screen and (max-width: em(450px)) {
    .breadcrumb--bottom {
        display: none;
    }
}
