@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

app-billing-tile {
    margin-bottom: Bolt.$space-2xl;
}

.billing-base-content billing-tile #billingOverview {
    margin-right: 0;
    margin-left: 0;
}

#billingOverview {
    font-size: inherit;
    margin: Bolt.$space-2xl, 0, Bolt.$space-2xl, 0;

    .alignment-right {
        text-align: right;
    }

    .bolt-button-space {
        margin-left: 0.5rem;
    }

    h2 {
        text-transform: none;
        font-size: 2.8rem;
        color: Bolt.$palette-vibrantBlue;
        font-weight: bold;
    }

    p {
        margin-bottom: 0;
    }

    .line--primary {
        font-size: inherit;
        margin-bottom: 0;
    }

    .line--secondary {
        font-size: inherit;
    }

    @media (max-width: 700px) {
        .alignment-right {
            text-align: left;
        }

        h2 {
            font-size: 2.7rem;
        }
    }

    @media (width: 768px) {
        h2 {
            font-size: 1.9rem;
        }
    }

    @media (max-width: 320px) {
        h2 {
            font-size: 2.5rem;
        }
    }

    @media (width: 1024px) {
        h2 {
            font-size: 2.4rem;
        }
    }

    @media (max-width: 767px) {
        .bolt-button-a-wc-secondary {
            width: 100%;
            display: flex;
        }

        .bolt-button-space {
            margin-left: 0;
            margin-bottom: Bolt.$space-md;
        }
    }

    a {
        border-bottom: 0;
    }
}

a.bolt-button-a-wc-secondary {
    font-size: 1.125rem;
    padding: 0.7rem 1.25rem;
    line-height: 1.125rem;
    border: 1px solid Bolt.$palette-vibrantBlue;
    color: Bolt.$palette-vibrantBlue;
    box-sizing: border-box;
    align-items: baseline;
    justify-content: center;
    font-family: "nw-primary", "proxima-nova", "Helvetica Neue", "Helvetica", sans-serif;
    border-radius: 4px;
    font-weight: 400;
    text-decoration: none;
    transition: all 350ms;
}

a:hover.bolt-button-a-wc-secondary,
a:focus.bolt-button-a-wc-secondary {
    text-transform: none;
    color: #fff;
    background: Bolt.$palette-vibrantBlue;
}
