//| TABLE-STYLE RULES AND OPTIONS
//|

//| DATA TABLE
//| Expected UL for each row inside container
//|

$border-color: #ccc;

//| SIMPLE 2-COLUMN TABLE
//| Intended for DL only
//|
dl.table-row {
    margin: 0;
    padding: 5px 0;

    dt,
    dd {
        padding: 0;
        margin: 0;
    }

    .table-row__label {
        float: left;
        width: 50%;
        font-weight: bold;
    }

    .table-row__value {
        float: left;
        width: 50%;
        text-align: right;
    }

    // Small size
    @include screensize(450) {
        .table-row__value {
            text-align: left;
        }
    }
}
