@import "_mixins";

/*
| Billing
|
*/

.ni-ss-billing {
    .accordion__header .icon-before::before {
        color: #005691;
        font-size: 32px;
        height: 32px;
        width: 32px;
    }

    .accordion__header small {
        padding-left: 3em;
        color: #000;
    }

    @include screensize(700) {
        .accordion__header small {
            padding-left: 0;
            display: block;
        }
    }

    @include screensize(610) {
        .billing-header .left {
            float: none;
            display: block;
            margin-bottom: 10px;
        }
    }
}

// Make a payment view
.billing-payments {
    .payment-form {
        display: none;
    }

    // Edit details link for payment method, saved methods only
    .edit-toggle {
        margin-left: 15px;
    }

    @include screensize(500) {
        .edit-toggle {
            margin: 5px 0 0;
            display: block;
        }
    }
}
