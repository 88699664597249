/* AUTO POLICY SUMMARY INFO */

.alert .media-body {
    vertical-align: middle;
}

.alert-box p:last-of-type {
    margin: 0;
}

@media screen and (max-width: 47.5em) {
    /* 760px */
    .alert-box p {
        margin: 0 0 5px;
    }
}

@media screen and (max-width: 32.5em) {
    /* 520px */
    .alert-box p {
        margin: 0 0 5px;
    }
}
