/* Icon message boxes with buttons
*/

.message-container {
    overflow: auto;
    width: 100%;
    border: 2px solid;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
    background-color: #fff;
    z-index: 998;
}

.message-container .before-sprite {
    padding-left: 50px;
    float: left;
    margin-bottom: 10px;
}

.message-container p {
    margin-bottom: 0;
}

.message-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.message-container li {
    margin: 0 0 0.75em;
}

.message-container li:last-child {
    margin: 0;
}

.message-container--information {
    border-color: #0c72b9;
}

// Common rules for icon messages
.message-container--information .before-sprite::before {
    content: " ";
    display: block;
    float: left;
    position: absolute;
    top: 20px;
    left: 15px;
}

.message-container--information .before-sprite::before {
    background-position: -1px -468px;
    height: 32px;
    width: 32px;
}
