@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

@media (max-width: 570px) {
    .bolt-content-space {
        margin-bottom: Bolt.$space-lg;
    }

    .bolt-content-space-xl {
        margin-bottom: Bolt.$space-xl;
    }

    .bolt-button-space-container {
        display: inline-block;
        width: 100%;
        margin-bottom: Bolt.$space-md;
    }

    .manage-payment-form-container {
        padding: 0;
    }
}

.manage-payment-form-container {
    a {
        text-decoration: none;
    }

    padding: 0;
}

.to-container {
    display: block;
}

.progress-bar-container {
    margin: Bolt.$space-xl 0;
}

.section-content {
    margin: 0;
    padding: 0;
}

@media (max-width: 756px) {
    .manage-payment-form-container {
        padding: 0;
    }
}

.timeline-widget-external {
    margin-top: Bolt.$space-xl;
}

@media (max-width: 896px) {
    .manage-payment-form-container {
        padding: 0;
    }
}

.fields-only {
    display: none;
}

.bolt-typography {
    bw-bolt-notification p {
        margin-bottom: 0;
    }
}
