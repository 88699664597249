.nav-header {
    outline: none;
}

/* MOBILE NAVIGATION */
.mobile-navigation {
    display: none;
    font-family: "proxima-nova", Arial, sans-serif;
    font-size: 1.1em;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: -265px;
    width: 265px;
    height: 100%;
    background: #d9d9d9;
    z-index: 999;
    -webkit-backface-visibility: hidden;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
}

.mobile-navigation .nw-no-underline,
.mobile-navigation .nw-no-underline:hover,
.mobile-navigation .nw-no-underline:focus {
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
}

.mobile-navigation ul {
    margin: 0;
    padding: 0;
}

.mobile-navigation__header {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    padding: 5px 10px;
    margin-bottom: 0;
    font-weight: 700;
    background: #b2b2b2;
    color: #333;
}

.mobile-navigation__primary {
    background-color: #fff;
}

.mobile-navigation__primary > li {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #b6b6b6;
}

.mobile-navigation__primary li:last-child {
    border-bottom: none;
}

.mobile-navigation__primary li a {
    display: block;
    padding: 10px 5px 10px 10px;
}

.mobile-navigation__primary li a:hover {
    background-color: #ececec;
}

.mobile-navigation__primary li.active > a {
    display: block;
    padding: 10px 5px 10px 10px;
    font-weight: bold;
    color: #333;
    background-color: #e5f0f7;
}

.mobile-navigation__primary li.select > a {
    display: block;
    padding: 10px 5px 10px 10px;
    font-weight: bold;
    color: #333;
    background-color: #e5f0f7;
}

.mobile-navigation__primary li ul {
    background: #faf7f7;
}

.mobile-navigation__primary li li {
    border-bottom-width: 1px solid #dbdbdb;
}

.mobile-navigation__primary li li:first-child {
    border-top: 1px solid #a3a3a3;
}

.mobile-navigation__primary li li:last-child {
    border-bottom: 1px solid #a3a3a3;
}

.mobile-navigation__primary li li a {
    font-size: 0.95em;
    font-weight: normal;
    padding: 10px 10px 10px 25px;
    color: #333;
}

.mobile-navigation__primary li li.active a {
    background: #d5e3ef;
    font-weight: bold;
    padding-left: 25px;
    color: #333;
}

.mobile-navigation__primary li li.select a {
    font-weight: bold;
    padding-left: 25px;
    color: #333;
    background-color: #d5e3ef;
}

.mobile-navigation__contact {
    padding: 10px 5px 10px 20px;
    color: #333; /* border-bottom:1px solid #bdbdbd; background-color: #dfdfdf; */
}

.mobile-navigation__contact a {
    text-decoration: none;
    color: #005691;
}

.mobile-navigation__contact li {
    line-height: 1.6em;
}

.mobile-navigation-home {
    display: block;
    text-align: left;
    padding: 10px;
    min-height: 41px;
    background: #fff;
}

.mobile-navigation-home:focus,
.mobile-navigation-home:hover {
    background-color: #ececec;
}

.mobile-navigation-home:active {
    background-color: #838383;
}

/* NAVIGATION SHOW/HIDE */
.js .show-nav .outer-container {
    /* -webkit-transform: translate(265px, 0); transform: translate(265px, 0); */
    margin-left: 265px;
}

.js .show-nav .mobile-navigation {
    /* -webkit-transform: translate(265px, 0); transform: translate(265px, 0); */
    left: 0;
}

.js .show-nav .mobile-nav-button {
    position: relative;
    z-index: 1000;
}

/* LEFT NAVIGATION */

ul.secondary-navigation__contact {
    margin-top: 10px;
}

ul.secondary-navigation__contact li {
    padding: 5px 5px 5px 20px;
}

/* NAVIGATION SHOW/HIDE */
.js .show-nav .outer-container {
    /* -webkit-transform: translate(265px, 0); transform: translate(265px, 0); */
    margin-left: 265px;
}

.js .show-nav {
    /* -webkit-transform: translate(265px, 0); transform: translate(265px, 0); */
    left: 0;
}

.js .show-nav .overlay {
    @include opacity(70, 0.7);

    z-index: 998;
}

@media screen and (max-width: 62.1875em) {
    /* 995px */

    .mobile-navigation {
        display: block;
    }
}
