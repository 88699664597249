/* PROGRESS BARS */
@import "partials/policy-variables";

.nw-progress {
    background-color: #e7e7e7;
    padding: 20px;
    color: #333;
    font-size: 0.875em;
    border-bottom: 1px solid #c3c3c3;
    display: block;
    margin-bottom: 20px;

    @media screen and (max-width: $mobile-landscape) {
        /* 600px */
        padding: 10px;
    }
}

.nw-progress span {
    margin-bottom: 10px;
    display: block;
}

// Override for Bolt progress bar
.progress {
    margin-bottom: 0;
    height: 27px;
    box-shadow: inset 0 1px 2px #0000004d;
    border: solid #0000001a 1px;
}
