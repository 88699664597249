/* BUTTONS */
.button-container {
    width: 100%;
    border-top: 1px solid #d6d6d6;
    padding: 20px;
    background-color: #cce1ef;
}

is-change-buttons-container .button-instructions {
    margin-bottom: 15px;
    float: none;
}

.button-instructions {
    float: right;
}

/* Override for Bolt Button */
.btn {
    font-size: 1.1em;
}

.button {
    @include border-radius(4px);

    border: 0;

    @include box-shadow(0, 1px, 1px, 0, #000333);

    cursor: pointer;
    min-width: 70px;
    padding: 10px 25px;
    text-decoration: none;
    text-align: center;
    overflow: visible;
    font-family: Arial, sans-serif;
    font-size: 1.1em;
    font-weight: bold;
}

.button:active {
    @include box-shadow(0, -1px, -1px, 0, #000333);

    transform: translate(1px, 1px);
    -webkit-transform: translate(1px, 1px);
}

.preferred {
    background: #0071bf;
    border: none;
    color: #fff;
}

.preferred:hover,
.preferred:focus {
    background: #005691;
}

.preferred--alt {
    background: #e65100;
    border: none;
    color: #fff;
}

.preferred--alt:hover,
.preferred--alt:focus {
    background: #b84000;
}

.non-preferred {
    background: #f5f5f5;
    color: #0071bf;
}

.non-preferred:hover,
.non-preferred:active,
.non-preferred:focus {
    background: #e2e2e2;
}

.button--disabled {
    @include opacity(25, 0.25);

    cursor: default;
    background: transparent;
    border: 1px solid #000;

    @include box-shadow(0, 0, 0, 0, #000);

    color: #000;
}

.preferred.button--disabled:hover,
.preferred.button--disabled:focus {
    background: transparent;
}

.non-preferred.button--disabled:hover,
.non-preferred.button--disabled:focus {
    background: transparent;
}

.button--disabled:active {
    @include box-shadow(0, 0, 0, 0, #000);

    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}

a.disabled:hover,
a.disabled:active,
a.disabled:focus {
    text-decoration: none;
}

.button-margin {
    margin-right: 15px;
}

.button-right {
    float: right;
}

.button-left {
    float: left;
}

@media screen and (max-width: 40.625em) {
    /* 650px */

    .button {
        padding: 8px 10px;
    }
}

@media screen and (min-width: 1px) and (max-width: 25.625em) {
    /* 410px */
    .button-container {
        padding-top: 20px;
    }

    .button-spacing {
        margin-bottom: 10px;
    }

    .equal-width {
        width: 100%;
        margin-right: 0;
    }

    .button {
        margin-bottom: 10px;
    }

    .button-top {
        margin-top: 10px;
    }

    .button-margin {
        margin-right: 0;
    }

    .button-instructions {
        float: none;
    }
}
