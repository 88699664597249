/* FORMS */
.form-container {
    margin-bottom: 20px;
}

.form-grouping {
    & .section-header {
        clear: both;
    }

    & .media,
    .col-margin {
        margin-left: 16px;
        margin-right: 16px;
    }

    &::after {
        clear: both;
    }
}

// BOLT Additions

$screen-xl: 1100px;
$screen-xs: 450px;
$screen-sm: 550px;
$screen-md: 750px;
$screen-lg: 960px;

.nw-input--x-short,
.input-group .nw-input--x-short {
    width: 16.5%;
}

.nw-input--short,
.input-group .nw-input--short {
    width: 25%;
}

.nw-input--md-short,
.input-group .nw-input--md-short {
    width: 33.333%;
}

.nw-input--medium,
.input-group .nw-input--medium {
    width: 50%;
}

.nw-input--md-long,
.input-group .nw-input--md-long {
    width: 66.666%;
}

.nw-input--long,
.input-group .nw-input--long {
    width: 75%;
}

.nw-input--full,
.input-group .nw-input--full {
    width: 100%;
}

@media (max-width: $screen-xs) {
    .nw-input-xxs--x-short,
    .input-group .nw-input-xxs--x-short {
        width: 16.5%;
    }

    .nw-input-xxs--short,
    .input-group .nw-input-xxs--short {
        width: 25%;
    }

    .nw-input-xxs--md-short,
    .input-group .nw-input-xxs--md-short {
        width: 33.333%;
    }

    .nw-input-xxs--medium,
    .input-group .nw-input-xxs--medium {
        width: 50%;
    }

    .nw-input-xxs--md-long,
    .input-group .nw-input-xxs--md-long {
        width: 66.666%;
    }

    .nw-input-xxs--long,
    .input-group .nw-input-xxs--long {
        width: 75%;
    }

    .nw-input-xxs--full,
    .input-group .nw-input-xxs--full {
        width: 100%;
    }
}

@media (min-width: $screen-xs) {
    .nw-input-xs--x-short,
    .input-group .nw-input-xs--x-short {
        width: 16.5%;
    }

    .nw-input-xs--short,
    .input-group .nw-input-xs--short {
        width: 25%;
    }

    .nw-input-xs--md-short,
    .input-group .nw-input-xs--md-short {
        width: 33.333%;
    }

    .nw-input-xs--medium,
    .input-group .nw-input-xs--medium {
        width: 50%;
    }

    .nw-input-xs--md-long,
    .input-group .nw-input-xs--md-long {
        width: 66.666%;
    }

    .nw-input-xs--long,
    .input-group .nw-input-xs--long {
        width: 75%;
    }

    .nw-input-xs--full,
    .input-group .nw-input-xs--full {
        width: 100%;
    }
}

@media (min-width: $screen-sm) {
    .nw-input-sm--x-short,
    .input-group .nw-input-sm--x-short {
        width: 16.5%;
    }

    .nw-input-sm--short,
    .input-group .nw-input-sm--short {
        width: 25%;
    }

    .nw-input-sm--md-short,
    .input-group .nw-input-sm--md-short {
        width: 33.333%;
    }

    .nw-input-sm--medium,
    .input-group .nw-input-sm--medium {
        width: 50%;
    }

    .nw-input-sm--md-long,
    .input-group .nw-input-sm--md-long {
        width: 66.666%;
    }

    .nw-input-sm--long,
    .input-group .nw-input-sm--long {
        width: 75%;
    }

    .nw-input-sm--full,
    .input-group .nw-input-sm--full {
        width: 100%;
    }
}

@media (min-width: $screen-md) {
    .nw-input-md--x-short,
    .input-group .nw-input-md--x-short {
        width: 16.5%;
    }

    .nw-input-md--short,
    .input-group .nw-input-md--short {
        width: 25%;
    }

    .nw-input-md--md-short,
    .input-group .nw-input-md--md-short {
        width: 33.333%;
    }

    .nw-input-md--medium,
    .input-group .nw-input-md--medium {
        width: 50%;
    }

    .nw-input-md--md-long,
    .input-group .nw-input-md--md-long {
        width: 66.666%;
    }

    .nw-input-md--long,
    .input-group .nw-input-md--long {
        width: 75%;
    }

    .nw-input-md--full,
    .input-group .nw-input-md--full {
        width: 100%;
    }
}

@media (min-width: $screen-lg) {
    .nw-input-lg--x-short,
    .input-group .nw-input-lg--x-short {
        width: 16.5%;
    }

    .nw-input-lg--short,
    .input-group .nw-input-lg--short {
        width: 25%;
    }

    .nw-input-lg--md-short,
    .input-group .nw-input-lg--md-short {
        width: 33.333%;
    }

    .nw-input-lg--medium,
    .input-group .nw-input-lg--medium {
        width: 50%;
    }

    .nw-input-lg--md-long,
    .input-group .nw-input-lg--md-long {
        width: 66.666%;
    }

    .nw-input-lg--md-long,
    .input-group .nw-input-lg--md-long {
        width: 75%;
    }

    .nw-input-lg--full,
    .input-group .nw-input-lg--full {
        width: 100%;
    }
}

@media (min-width: $screen-xl) {
    .nw-input-xl--x-short,
    .input-group .nw-input-xl--x-short {
        width: 16.5%;
    }

    .nw-input-xl--short,
    .input-group .nw-input-xl--short {
        width: 25%;
    }

    .nw-input-xl--md-short,
    .input-group .nw-input-xl--md-short {
        width: 33.333%;
    }

    .nw-input-xl--medium,
    .input-group .nw-input-xl--medium {
        width: 50%;
    }

    .nw-input-xl--md-long,
    .input-group .nw-input-xl--md-long {
        width: 66.666%;
    }

    .nw-input-xl--long,
    .input-group .nw-input-xl--long {
        width: 75%;
    }

    .nw-input-xl--full,
    .input-group .nw-input-xl--full {
        width: 100%;
    }
}

// BOLT Override
// This will make our lives sooo much easier
select.form-control {
    width: auto;
    max-width: 100%;

    &:not([size]):not([multiple]) {
        height: 39px;
    }
}

legend {
    font-size: 16px;
    font-weight: bold;
}

.clearfix {
    clear: both;
}

.radio-inline input[type="radio"] {
    height: 100%;
    vertical-align: middle;
    margin-top: 1px;

    @media (max-width: em(450px)) {
        margin-top: 0;
    }
}

@media screen and (max-width: 28.125em) {
    legend {
        font-size: 1.1em;
    }
}

textarea.form-control.height-85 {
    font-size: 1em;
    height: 85px;
}

.radio-check-container {
    padding-bottom: 20px;
}

.radio-check-container legend {
    padding-bottom: 10px;
}

.field-subgroup {
    padding-left: 30px;

    &.col-xs-12,
    &.col-sm-12 {
        padding-right: 30px;
    }
}

.weight-normal {
    font-weight: normal;
}

.weight-bold {
    font-weight: bold;
}

.form-separator {
    border-top: 1px dotted #d1d1d1;
    margin: 0 0 20px;
}

.field-spacing {
    margin-bottom: 20px;

    &.schema-form-text,
    &.schema-form-radios,
    &.schema-form-radios-inline,
    &.schema-form-select {
        z-index: 10;
    }
}

.help-block {
    margin-bottom: 5px;
}

.has-error {
    background-color: #fff;

    & .control-label {
        color: #000;
    }
}

@media screen and (max-width: em(470px)) {
    .field-subgroup {
        padding-left: 20px;

        &.col-xs-12,
        &.col-sm-12 {
            padding-right: 20px;
        }
    }

    .form-separator {
        border-top: 1px dotted #d1d1d1;
        margin: 0 0 20px;
    }
}

.alert.alert-danger.nw-field-error {
    display: none;
}

.has-error .alert.alert-danger.nw-field-error {
    display: inline-block;
}

input[type="radio"],
input[type="checkbox"] {
    margin-right: 5px;
}

.input-group-addon.custom {
    border: none;
    color: #000;
    font-weight: bold;
    background: none;
    padding-left: 0;
    padding-right: 6px;
}

.input-group.custom .form-control:last-child,
.input-group-addon.custom:last-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: #000;
}

.sub-help {
    color: #777;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

//**************************************************************************************************************************

.form-control.read-only {
    display: none;
}

//**************************************************************************************************************************

.has-error .alert.alert-danger.nw-field-error.checkbox-class {
    display: block;
    margin-bottom: 16px;
    margin-top: -10px;
}

.col-xs-12.clearfix.new-row.hide-vin {
    display: none;
}
