/* PROGRESSIVE DISCLOSURE */

.progressive-disclosure__link {
    position: relative;
    padding-right: 15px;
}

.progressive-disclosure__arrow {
    display: block;
    position: absolute;
    top: 6px;
    right: 0;
    border: 5px solid transparent;
    border-top-color: #005691;
}

.progressive-disclosure__panel--fixed {
    max-height: 0;
    overflow: hidden;

    @include transition(max-height, 0.2s, ease-out);
}

.js.no-csstransitions .progressive-disclosure__panel--fixed {
    max-height: none;
    display: none;
}

.js .progressive-disclosure__panel--variable {
    max-height: none;
    display: none;
}

.show-disclosure .progressive-disclosure__arrow {
    border-top-color: transparent;
    border-bottom-color: #005691;
    top: 10px;
}

.show-disclosure .progressive-disclosure__panel--fixed {
    max-height: 500px;
}
